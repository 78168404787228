'use client';

import { usePathname } from 'next/navigation';

const Index = () => {
    const pathname = usePathname();

    if (pathname === '/feedback') return null;

    return (
        <a
            aria-label="Feedback"
            href="/feedback"
            className="flex bg-slate-900 text-white fixed top-1/2 -translate-y-1/2 right-0 z-[99] w-6 md:w-10 h-20 md:h-24 justify-center items-center rounded-tl-md rounded-bl-md hover:bg-slate-800 cursor-pointer shadow-lg shadow-slate-700"
        >
            <p className="rotate-90 text-center font-semibold text-xs md:text-base">Feedback</p>
        </a>
    );
};

export default Index;
