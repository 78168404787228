'use client';

import { BaseUser } from '@/api/authApi';
import { useGetCurrentUser } from '@/api/hooks/auth';
import { getContrast } from '@/helper/get-contrast';
import { extractName } from '@/helper/get-short-name';
import { stringToColor } from '@/helper/string-to-color';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { FaUserGraduate } from 'react-icons/fa6';
import { GiTeacher } from 'react-icons/gi';
import { IoHeart } from 'react-icons/io5';
import { MdBusinessCenter, MdHelp, MdHome, MdLibraryBooks, MdPolicy } from 'react-icons/md';
import TicTacLapLogo from '../../assets/images/logo/logo.png';
import Loader from '../Loader';

const Index = () => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);
    const { refetch, isLoading } = useGetCurrentUser();
    const [user, setUser] = React.useState<BaseUser | null>(null);

    React.useEffect(() => {
        const validate = async () => {
            try {
                const res = await refetch();
                setUser(res.data || null);
                setIsLoggedIn(true);
            } catch (error) {
                setIsLoggedIn(false);
                setUser(null);
            }
        };
        const cookies = document.cookie.split(';');
        let role = '';
        for (let i = 0; i < cookies.length; i++) {
            if (cookies[i].includes('role')) {
                role = cookies[i].split('=')[1];
            }
        }

        if (role === 'student' || role === 'teacher') {
            validate();
        } else {
            setIsLoggedIn(false);
        }
    }, [refetch]);

    return (
        <nav className="bg-gray-900 sticky top-0 z-[100]">
            <div className="mx-auto max-w-7xl px-3 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                    <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                        <button
                            type="button"
                            className="relative inline-flex items-center justify-center rounded-lg p-2 text-white hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            aria-label="Open menu"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <span className="absolute -inset-0.5"></span>
                            <span className="sr-only">Open main menu</span>

                            {navbarOpen ? (
                                <svg
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="block h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                    <div className="flex flex-1 items-center pl-12 lg:pl-0 justify-start max-w-24 lg:max-w-14">
                        <Image src={TicTacLapLogo} alt="TicTacLap Logo" width={100} height={100} />
                        <p className="text-white text-lg lg:text-2xl font-semibold ml-2">
                            TicTacLap
                        </p>
                    </div>
                    <div className="sm:ml-6 block">
                        <div className="flex space-x-2">
                            <Link
                                href="/"
                                className="bg-transparent hidden text-white rounded-lg font-semibold px-3 py-2 sm:flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                            >
                                <MdHome
                                    style={{
                                        fontSize: '1.25rem',
                                    }}
                                />
                                <span className="ml-2 text-sm hidden md:inline-block">Home</span>
                            </Link>
                            <Link
                                href="/about"
                                className="bg-transparent hidden text-white rounded-lg font-semibold px-3 py-2 lg:flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                            >
                                <MdBusinessCenter
                                    style={{
                                        fontSize: '1.25rem',
                                    }}
                                />
                                <span className="ml-2 text-sm hidden md:inline-block">
                                    About us
                                </span>
                            </Link>
                            <a
                                href="#footer"
                                className="bg-transparent hidden text-white rounded-lg font-semibold px-3 py-2 lg:flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                            >
                                <MdHelp
                                    style={{
                                        fontSize: '1.25rem',
                                    }}
                                />
                                <span className="ml-2 text-sm hidden md:inline-block">Help</span>
                            </a>
                            {isLoading || user ? (
                                <UserButton isLoading={isLoading} user={user} />
                            ) : (
                                <>
                                    <a
                                        href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/login'}
                                        className="bg-transparent text-white rounded-lg font-semibold px-3 py-2 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                                        aria-label="Go to Teacher login page"
                                    >
                                        <GiTeacher
                                            style={{
                                                fontSize: '1.25rem',
                                            }}
                                        />
                                        <span className="ml-2 text-sm hidden md:inline-block">
                                            Teacher
                                        </span>
                                    </a>

                                    <a
                                        href={
                                            process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/login/student'
                                        }
                                        className="bg-transparent text-white rounded-lg font-semibold px-3 py-2 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                                        aria-label="Go to Student login page"
                                    >
                                        <FaUserGraduate
                                            style={{
                                                fontSize: '1.15rem',
                                            }}
                                        />
                                        <span className="ml-2 hidden md:inline-block text-sm">
                                            Student
                                        </span>
                                    </a>
                                </>
                            )}
                            <a
                                href="https://donorbox.org/support-tictaclap-portal-for-pe-teachers?default_interval=m"
                                className="dbox-donation-button bg-blue-600 text-white rounded-lg px-3 py-2 font-semibold flex items-center hover:bg-blue-500/90 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300"
                                aria-label="Support us on Donorbox"
                            >
                                <IoHeart
                                    style={{
                                        fontSize: '1.15rem',
                                    }}
                                />
                                <span className="ml-2 hidden md:inline-block text-sm">Support</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative">
                <div
                    className={
                        'absolute top-0 py-2 lg:hidden w-full sm:max-w-96 border-t-2 border-slate-500 opacity-0 bg-gray-900 -translate-x-[100%] transition-all z-[500] ' +
                        (navbarOpen ? '!translate-x-0 !opacity-100' : '')
                    }
                    id="mobile-menu"
                >
                    <div className="space-y-2 px-3 pb-3 overflow-y-auto h-dvh">
                        <Link
                            href="/"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdHome
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">Home</span>
                        </Link>
                        {!isLoggedIn && (
                            <>
                                <a
                                    href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/login'}
                                    onClick={() => setNavbarOpen(false)}
                                    className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                                >
                                    <GiTeacher
                                        style={{
                                            fontSize: '1.25rem',
                                        }}
                                    />
                                    <span className="ml-2 text-sm">Teacher</span>
                                </a>

                                <a
                                    href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/login/student'}
                                    onClick={() => setNavbarOpen(false)}
                                    referrerPolicy="same-origin"
                                    className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                                >
                                    <FaUserGraduate
                                        style={{
                                            fontSize: '1.15rem',
                                        }}
                                    />
                                    <span className="ml-2 text-sm">Student</span>
                                </a>
                            </>
                        )}
                        <Link
                            href="/about"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdBusinessCenter
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">About us</span>
                        </Link>
                        {/* <Link
                            href="/data-protection"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdPolicy
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">Data Protection</span>
                        </Link>
                        <Link
                            href="/terms"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdLibraryBooks
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">Terms and Conditions</span>
                        </Link>
                        <Link
                            href="/legal-mentions"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdLibraryBooks
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">Legal Mentions</span>
                        </Link>
                        <Link
                            href="/ccpa-and-gdpr-rules"
                            onClick={() => setNavbarOpen(false)}
                            className="bg-transparent text-white rounded-lg font-semibold px-3 py-3 flex items-center hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-400"
                        >
                            <MdPolicy
                                style={{
                                    fontSize: '1.25rem',
                                }}
                            />
                            <span className="ml-2 text-sm">CCPA and GDPR</span>
                        </Link> */}

                        <a
                            href="https://donorbox.org/support-tictaclap-portal-for-pe-teachers?default_interval=o"
                            className="dbox-donation-button bg-blue-600 text-white rounded-lg px-3 py-3 font-semibold flex items-center hover:bg-blue-500/90 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-300"
                            onClick={() => setNavbarOpen(false)}
                        >
                            <IoHeart
                                style={{
                                    fontSize: '1.15rem',
                                }}
                            />
                            <span className="ml-2 text-sm">Support</span>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Index;

interface UserButtonProps {
    isLoading: boolean;
    user: BaseUser | null;
}

const UserButton = (props: UserButtonProps) => {
    const { isLoading, user } = props;
    const short = extractName(user?.name || '');
    const color = stringToColor(user?.name || '');
    const contrastColor = getContrast(color);

    if (isLoading) {
        return (
            <div className="w-10 h-10 rounded-full text-center text-lg font-semibold uppercase flex items-center justify-center p-2">
                <Loader className="text-white" />
            </div>
        );
    }

    return (
        <a href={process.env.NEXT_PUBLIC_PORTAL_ROUTE + '/'}>
            <div
                className={`w-9 h-9 rounded-full text-center text-lg font-semibold uppercase flex items-center justify-center p-2 hover:scale-105 transition-all cursor-pointer text-${contrastColor} bg-[${color}]`}
                style={{
                    background: color,
                }}
            >
                {short}
            </div>
        </a>
    );
};
