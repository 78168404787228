'use client';
import Script from 'next/script';
import { PropsWithChildren } from 'react';

const DonorBoxPvorider: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <>
            <Script
                type="text/javascript"
                defer
                src="https://donorbox.org/install-popup-button.js"
                id="donorbox-popup-button-installer"
            />
            {children}
        </>
    );
};

export default DonorBoxPvorider;
