export function extractName(name: string) {
    if (name.length > 0) {
        const splitted = name.replace(/\s\s+/g, ' ').trim().split(' ');
        return `${splitted[0][0]}${
            splitted.length > 1
                ? splitted[splitted.length - 1][0]
                : splitted[0].length > 1
                  ? splitted[0][1]
                  : ''
        }`;
    }
    return '';
}
