import { useQuery } from "@tanstack/react-query";
import { AuthApi } from "../authApi";

export const useGetCurrentUser = () => {
    return useQuery({
        queryKey: ['current-user'],
        queryFn: () => new AuthApi().getLoggedInUser(),
        retry: false,
        enabled: false,
    });
};