import axios, { AxiosResponse } from 'axios';

const axiosInstance = axios.create({
    withCredentials: true,
});

const rootURL = process.env.NEXT_PUBLIC_BACKEND_API_URL;

export class ApiBase {
    protected host = rootURL;
    protected axiosInstance = axiosInstance;
    validateResponse(response: AxiosResponse) {
        if (response.status !== 200 && response.status !== 201)
            return Promise.reject(response.data);
    }
}
