'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, useState } from 'react';

const ReactQueryPvorider: React.FC<PropsWithChildren> = ({ children }) => {
    const [queryClientStore] = useState(() => new QueryClient());
    return <QueryClientProvider client={queryClientStore}>{children}</QueryClientProvider>;
};

export default ReactQueryPvorider;
