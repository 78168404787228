import { ApiBase } from "./apiBase";

export enum Role {
    TEACHER = 'teacher',
    STUDENT = 'student',
}

export interface BaseUser {
    publicId: string;
    name: string;
    role: Role;
}

export class AuthApi extends ApiBase {
    private baseUrl = this.host + '/auth';

    async getLoggedInUser() {
        const response = await this.axiosInstance.get(this.baseUrl + '/me');
        this.validateResponse(response);
        const { data } = response;
        return data as BaseUser;
    }
}